import styled from 'styled-components'

interface IStyledInputIcon {
  icon?: string
}

export const StyledInputIcon = styled.button<IStyledInputIcon>`
    background-image: url(${(props) => `${props.icon}`});
    background-color: white;
    background-repeat: no-repeat;
    border: 0px;
    border-radius: 0px;
    cursor: pointer;
    opacity: 0.7;
    width: 1.8125rem;
    height: 0.9375rem;
    margin: 1.1rem 0.3rem 0.3rem 0.3rem;

    &:focus {
      border: 0px;
      outline: none;
    }
`
