import styled from 'styled-components'

import { COLORS } from '@probatix/common/constants/colors'
import { BORDER_RADIUS } from '@probatix/myprobatix/src/constants'
import { StyledSelect } from '../Select/Select.styled'

export const Card = styled.div`
  width: 100%;
  margin: auto;
  border: 1px solid ${COLORS.BORDER};
  background: ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS};
  overflow: hidden;
`

export const CardHeader = styled.div`
  padding: 16px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardContent = styled.div`
  padding: 16px;
`

export const Select = styled(StyledSelect)`
  position: relative;
  display: inline-block;
  padding: 8px 10px 6px 17px;
  line-height: 29px;
  border-radius: 4px;
  border: 1px solid #f5f5f5ad;
  font-size: 14px;
  width: auto;
  min-width: 150px;
`
