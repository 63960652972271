import React, { useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaPredicate } from 'react-media-hook'
import { useLocation } from 'react-router-dom'

import { COLORS } from '@probatix/common/constants/colors'
import { DEFAULT_MOBILE_MAX_WIDTH } from '@probatix/common/constants/design'

import {
  LoginCredentials,
  AuthenticatedUser,
} from '@probatix/common/services/toolkit/auth/auth.model'
import SessionStorage from '@probatix/common/utils/sessionStorage'

import {
  authServiceActions,
  authServiceSelectors,
} from '@probatix/common/services/toolkit/auth'

import { BUTTON_VERSION } from '@probatix/common/components/atoms/Button/Button.styled'
import { generateAssetUrl } from '@probatix/common/components/atoms/TextField/TextField.helpers'

import {
  Button,
  Icon,
  Logo,
  Page,
  Popover,
  Space,
  TextField,
  Typography,
  LanguageSwitcher,
} from '@probatix/common/components/atoms'

import { CustomModal } from '@probatix/common/components/molecules'

import { Trans } from 'translations'

import {
  API_MY_PROBATIX_ENTRYPOINT,
  MY_PROBATIX_URL,
  SESSION_KEY,
} from 'config/app'

import logo from '@probatix/checkout/src/assets/probatix-logo.png'

import { ApiError, getFirstErrorOrDetail, returnFirstErrorForPropertyPaths } from '@probatix/common/utils/url'
import {
  StyledMainHeaderWrapper,
  StyledMainHeaderContainer,
  StyledSignInButton,
  StyledLink,
  StyledSignInContainer,
  StyledFormWrapper,
  StyledHR,
} from './MainHeader.styled'

const MainHeader = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [signInMenuIsOpen, setSignInMenuIsOpen] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const isKiosk = null !== localStorage.getItem('isCheckoutKiosk')
  const isMobile = useMediaPredicate(DEFAULT_MOBILE_MAX_WIDTH)
  const {
    control,
    handleSubmit,
    register,
    setError,
  }: {
    control: Control<LoginCredentials>;
    handleSubmit: any;
    register: any;
    setError: any;
  } = useForm<LoginCredentials>()

  const auth = useSelector(authServiceSelectors.getAuthState)

  const handleLogout = () => {
    setSignInMenuIsOpen(false)
    SessionStorage.remove(SESSION_KEY)
    dispatch(authServiceActions.logout())
  }

  const onSubmit = (values: LoginCredentials) => {
    setIsSubmitting(true)

    dispatch(
      authServiceActions.login({
        customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
        data: values,
        onErrorCallback: (err: ApiError) => {
          let errorSet = false
          returnFirstErrorForPropertyPaths(err).forEach((errorMessage, propertyPath) => {
            const error = { message: errorMessage, type: 'manual' }
            setError(propertyPath, error)
            errorSet = true
          })

          if (!errorSet) {
            const error = { message: getFirstErrorOrDetail(err), type: 'manual' }
            setError('password', error)
          }

          setIsSubmitting(false)
        },
        onSuccessCallback: () => {
          dispatch(
            authServiceActions.me({
              customEntrypoint: API_MY_PROBATIX_ENTRYPOINT,
              onFinallyCallback: () => setSignInMenuIsOpen(false),
              onSuccessCallback: (response: AuthenticatedUser) => {
                if (!pathname.endsWith('/payment')) {
                  dispatch(authServiceActions.setAuthData(response))
                }
                setIsSubmitting(false)
              },
            }),
          )
        },
      }),
    )
  }

  const renderSignInForm = () => (
    <form name="signIn" onSubmit={handleSubmit(onSubmit)}>
      <StyledFormWrapper>
        <TextField
          control={control}
          errorMargin="0px"
          label={<Trans k="shell:mainHeader:email" />}
          type="email"
          {...register('username', { required: true })}
        />
        <TextField
          control={control}
          errorMargin="0px"
          label={<Trans k="shell:mainHeader:password" />}
          type="password"
          variant="password"
          {...register('password', { required: true })}
        />
        <Button
          dataQa="signInButton"
          label={<Trans k="shell:mainHeader:signIn" />}
          loading={isSubmitting}
          radius="6px"
          size="small"
          oneColumn
          submit
        />
        <Space space="10px" />
        <Typography align="center" fontSize="15px">
          <Trans k="shell:mainHeader:newCustomer" />
          {' '}
          <a
            data-qa="registerNowButton"
            href={`${MY_PROBATIX_URL}/signup` || '#'}
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              color={COLORS.BLUE_LIGHT}
              textDecoration="underline"
              variant="span"
            >
              <Trans k="shell:mainHeader:registerNow" />
            </Typography>
          </a>
        </Typography>
        <Space space="10px" />
        <LanguageSwitcher checkout />
      </StyledFormWrapper>
    </form>
  )

  const renderMenuButton = (authenticated: boolean) => {
    const label = authenticated ? (
      'MyProbatix'
    ) : (
      <Trans k="shell:mainHeader:signIn" />
    )

    if (!isKiosk) {
      return (
        <StyledSignInButton
          onClick={
            isMobile ? () => setSignInMenuIsOpen(!signInMenuIsOpen) : () => { }
          }
        >
          {!isMobile && (
            <Typography fontSize="15px" fontWeight={600}>
              {label}
            </Typography>
          )}
          <Icon icon="signIn" size={28} />
        </StyledSignInButton>
      )
    }

    return <div />
  }

  const renderAuthenticatedMenu = () => (
    <Popover
      button={renderMenuButton(true)}
      isOpen={signInMenuIsOpen}
      margin={isMobile ? '0 10px 0 0' : ''}
      options={{
        auto: false,
        triggerOffset: isMobile ? 10 : 20,
      }}
      placement="bottom-end"
      zIndex={11}
      disableCloseInside
      onClick={setSignInMenuIsOpen}
      onOutsideClick={() => setSignInMenuIsOpen(false)}
    >
      <StyledFormWrapper>
        <Space space="10px" />
        <StyledLink
          target="_blank"
          to="/login"
        >
          <Trans k="global:goTomyProbatix" />
        </StyledLink>
        <Space space="5px" />
        <StyledHR />
        <Button
          dataQa="logoutButton"
          fontWeight={400}
          label={<Trans k="shell:mainHeader:logout" />}
          loading={isSubmitting}
          size="small"
          textColor={COLORS.ECLIPSE}
          version={BUTTON_VERSION.FULL_TRANSPARENT}
          capitalize
          onClick={handleLogout}
        />
        <StyledHR />
        <Space space="10px" />
        <LanguageSwitcher paddingInlineEnd="25px" checkout />
      </StyledFormWrapper>
    </Popover>
  )

  const renderSignInMobileSection = () => {
    if (auth.data?.uuid) {
      return renderAuthenticatedMenu()
    }

    return (
      <React.Fragment>
        {renderMenuButton(false)}
        <CustomModal
          isOpen={signInMenuIsOpen}
          onClose={() => setSignInMenuIsOpen(false)}
          onOpen={() => setSignInMenuIsOpen(true)}>
          <Space padding="40px 0 0" />
          {renderSignInForm()}
        </CustomModal>
      </React.Fragment>
    )
  }

  const renderSignInSection = () => {
    if (auth.data?.email) {
      return renderAuthenticatedMenu()
    }

    return (
      <Popover
        button={renderMenuButton(false)}
        isOpen={signInMenuIsOpen}
        placement="bottom-end"
        disableCloseInside
        onClick={setSignInMenuIsOpen}
        onOutsideClick={() => setSignInMenuIsOpen(false)}
      >
        <StyledSignInContainer>{renderSignInForm()}</StyledSignInContainer>
      </Popover>
    )
  }

  return (
    <StyledMainHeaderWrapper data-testid="main-header">
      <Page>
        <StyledMainHeaderContainer>
          <StyledLink to="pick-slot">
            <Logo
              height={60}
              margin={0}
              src={generateAssetUrl(logo)}
              width={140}
            />
          </StyledLink>
          {!isMobile && renderSignInSection()}
          {isMobile && renderSignInMobileSection()}
        </StyledMainHeaderContainer>
      </Page>
    </StyledMainHeaderWrapper>
  )
}

export default MainHeader
