import styled from 'styled-components'

interface IProgressFill {
  color: string,
  progress: number
}

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 7px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
`

export const ProgressFill = styled.div<IProgressFill>`
  height: 7px;
  width: ${(props) => props.progress}%;
  background-color:${(props) => props.color};
  border-radius: 10px 0 0 10px;
  transition: width 0.3s ease-in-out;
`
